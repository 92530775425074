import {
  TemplateConfig,
  TemplateConfigField
} from "@/interfaces/templateEditor";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./elementSidebar.template.vue";

@Component({
  mixins: [Template]
})
export default class ElementSidebarComponent extends Vue {
  @Prop({ required: true })
  public config!: TemplateConfig;

  constructor() {
    super();
  }
  get configCopy() {
    return cloneDeep(this.config);
  }
  public switchHandler(fieldName: string, configField: TemplateConfigField) {
    this.$emit("visibilityChanged", { fieldName, configField });
  }
}
