import { blockItems } from "@/components/templateEditor/editorItems/UIBlock.declarations";
import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { EventBus } from "@/internal";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./mainConfiguration.template.vue";

@Component({
  mixins: [Template]
})
export default class MainConfigurationComponent extends Vue {
  @Prop() public model!: TemplateEditor.TemplateModel;

  protected defaults: TemplateEditor.TemplateModel = {
    name: "",
    type: "",
    creation_date: "",
    default: false,
    size: {
      width: 400,
      height: 400,
      unit: "px"
    },
    items: [],
    tree: []
  };

  protected possibleElements: object[] = [
    { icon: "fal fa-images", text: "images", type: "image-item" },
    { icon: "fal fa-grip-lines", text: "lines", type: "line-item" },
    { icon: "[VAR]", text: "variables", type: "var-item" },
    { icon: "fal fa-barcode", text: "barcode", type: "barcode-item" },
    { icon: "fal fa-paragraph", text: "text", type: "text-item" },
    { icon: "fal fa-list", text: "list", type: "list-item" }
  ];

  protected title: string = "Template";
  protected subtitle: string = "Click on elements to add";

  protected itemFactory(type: string) {
    const selectedBlock = {
      type,
      model: { ...blockItems[type], newBlock: true }
    };
    EventBus.$emit("TemplateEditor.AddNew", { block: selectedBlock });
  }

  protected requiredRule(value: number) {
    return !!value || this.$t("required_field_error").toString();
  }

  protected get values() {
    return this.model;
  }
}
