import { Component } from "vue-property-decorator";
import Template from "./Checkbox.template.vue";

import ConfigurationField from "../ConfigurationField.mixin";
import { ConfigurationFields } from "../declarations";

@Component({
  mixins: [Template]
})
export default class ConfigurationFieldCheckbox extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.Checkbox = {};
}
