import { Component, Prop, Vue } from "vue-property-decorator";
import TemplateEditor from "../editors.declaration";
import Template from "./Canvas.template.vue";

import { Drop } from "vue-drag-drop";

import { EventBus } from "@/internal";
import TestItem from "../editorItems/TestItem/TestItem.component";
import UIBlockBarcodeFieldComponent from "../editorItems/UIBlockBarcodeField/UIBlockBarcodeField.component";
import UIBlockImageFieldComponent from "../editorItems/UIBlockimageField/UIBlockImageField.component";
import UIBlockLineFieldComponent from "../editorItems/UIBlockLineField/UIBlockLineField.component";
import UIBlockListFieldComponent from "../editorItems/UIBlockListField/UIBlockListField.component";
import UIBlockTextFieldComponent from "../editorItems/UIBlockTextField/UIBlockTextField.component";
import UIBlockVarFieldComponent from "../editorItems/UIBlockVarField/UIBlockVarField.component";
import CanvasTreeComponent from "./canvas-tree/CanvasTree.component";

@Component({
  mixins: [Template],
  components: {
    "test-item": TestItem,
    "text-item": UIBlockTextFieldComponent,
    "var-item": UIBlockVarFieldComponent,
    "list-item": UIBlockListFieldComponent,
    "line-item": UIBlockLineFieldComponent,
    "image-item": UIBlockImageFieldComponent,
    "barcode-item": UIBlockBarcodeFieldComponent,
    "tree-component": CanvasTreeComponent,
    "drop-zone": Drop
  }
})
export default class CanvasComponent extends Vue {
  @Prop({ required: true })
  protected configuration!: TemplateEditor.TemplateTypeConfiguration;
  @Prop({ default: false }) protected viewMode!: boolean;
  @Prop() protected values!: TemplateEditor.TemplateModel;

  protected dragging: boolean = false;

  public openEdit($event: any) {
    this.$emit("edit", $event);
  }

  public clickWorkspace(event: any) {
    EventBus.$emit("exitEdit");
  }

  public removeElement(data: {
    item: TemplateEditor.UIBlocks.BaseBlockModel;
    breadcrumb: number[];
  }) {
    EventBus.$emit("TemplateEditor.RemoveElement", {
      breadcrumb: data.breadcrumb
    });
    EventBus.$emit("TemplateEditor.DraggingEnded");
  }

  protected get canvasSize() {
    return {
      height: this.values.size!.height + this.values.size!.unit,
      width: this.values.size!.width + this.values.size!.unit
    };
  }
  protected get tree() {
    return this.values.tree;
  }
}
