import { TemplateConfig } from "@/interfaces/templateEditor";
import forIn from "lodash/forIn";
import kebabCase from "lodash/kebabCase";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class EditMixin extends Vue {
  @Prop({ default: 1 })
  public zoom!: number;
  @Prop({ required: true })
  public templateConfig!: TemplateConfig;
  @Prop({ default: "" })
  public currentlyEditing!: string;
  @Prop({ default: false })
  public print!: boolean;

  public currentEdit = "";

  public openEdit(fieldName: string) {
    this.currentEdit = fieldName;
    this.$emit("openEdit", { field: fieldName });
  }

  public getFieldStyle(fieldDisplay: any) {
    if (fieldDisplay && fieldDisplay.style) {
      const styles: string[] = [];
      forIn(fieldDisplay.style, (val, k) => {
        if (!!+val) {
          val = val + "px";
        }
        styles.push(`${kebabCase(k)}: ${val}`);
      });
      return styles.join("; ");
    }
  }

  get barcodeConfig() {
    return {
      textAlign:
        this.templateConfig.barcode.fieldDisplay!.style.textAlign || "center",
      fontSize: this.templateConfig.barcode.fieldDisplay!.style.fontSize || 16,
      height: this.templateConfig.barcode.fieldDisplay!.style.lineHeight || 32,
      width: 1,
      marginTop: this.templateConfig.barcode.fieldDisplay!.style.marginTop || 0,
      marginBottom:
        this.templateConfig.barcode.fieldDisplay!.style.marginBottom || 0,
      marginLeft: 0,
      marginRight: 0,
      format: "CODE128",
      tag: "svg",
      font: "Arial"
    };
  }

  protected mounted() {
    if (this.print) {
      this.$nextTick(() => this.$emit("readyToPrint"));
    }
  }
}
