import { pusherEvents } from "@/enums/pusherEvents";
import { PusherNotification } from "@/interfaces/notification";
import { EventBus } from "@/internal";
import { templatesService } from "@/services/templates.service";
import { Callback, PageNavAction } from "@/types/types";
import { ActionsSubheaderComponent } from "helix-vue-components";
import { cloneDeep } from "lodash";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import CanvasTreeComponent from "./canvas/canvas-tree/CanvasTree.component";
import CanvasComponent from "./canvas/Canvas.component";
import ConfigurationStepComponent from "./configuration/layout/configurationStep/configurationStep.component";
import MainConfigurationComponent from "./configuration/layout/mainConfiguration/mainConfiguration.component";
import { defaultTemplateModel, TemplateEditor } from "./editors.declaration";
import TemplateItemsFactoryComponent from "./ItemsFactory.class";
import Template from "./TemplateEditor.template.vue";

const namespace = "TemplateModelModule";
@Component({
  mixins: [Template],
  components: {
    "configuration-step-component": ConfigurationStepComponent,
    "main-configuration-component": MainConfigurationComponent,
    "canvas-component": CanvasComponent
  },
  inject: ["$changes"]
})
export default class TemplateEditorComponent extends Vue {
  @Action("saveTemplateModel", { namespace })
  public saveTemplateModel!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public loading = false;
  protected editorTitle: string = "";
  protected type: string = "";

  protected configuration: TemplateEditor.TemplateTypeConfiguration | null = null;
  protected values: TemplateEditor.TemplateModel | null = null;
  protected initialTemplate: TemplateEditor.TemplateModel | null = null;

  protected activeBlock: TemplateEditor.UIBlocks.Block | null = null;

  protected factory: TemplateItemsFactoryComponent = new TemplateItemsFactoryComponent();

  @Action("errorRedirect", { namespace: "RouterModule" })
  protected errorRedirect!: Callback;

  protected mounted() {
    this.setup();

    this.setPageNav({
      title: this.composedTitle,
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.saveTemplate,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          }
        ]
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-print",
              action: this.printTemplate,
              vuetifyProps: () => ({
                loading: this.loading,
                fab: true,
                small: true
              })
            }
          ]
        }
      }
    });

    // Listen to template changes via pusher
    if (this.$route.params.id) {
      this.$changes.watch(
        pusherEvents.templateTouched,
        this.setup,
        (data: PusherNotification) =>
          !!data.message.find(n => n.item_id === +this.$route.params.id)
      );
    }
    EventBus.$on("exitEdit", () => (this.activeBlock = null));
    EventBus.$on("TemplateEditor.ExitEditBar", () => {
      this.activeBlock = null;
    });
    EventBus.$on("templateEditorEdit", (payload: any) => {
      this.edit(payload);
    });
  }

  protected async setup() {
    this.loading = true;
    if (!this.$route.params.id) {
      const params = {
        size: {
          width: Number(this.$route.params.width),
          height: Number(this.$route.params.height),
          unit: this.$route.params.unit as "px" | "%" | "in"
        },
        type: this.$route.params.type
      };
      this.type = this.$route.params.type;
      this.values = {
        ...defaultTemplateModel,
        ...params,
        name: this.$route.params.type,
        // without this, the object keeps the reference and modifies default.
        tree: [...defaultTemplateModel.tree!]
      };
      this.editorTitle = this.$t("templates.new").toString();
    } else {
      try {
        this.initialTemplate = await templatesService.getTemplate(
          this.$route.params.id
        );
        this.values = this.initialTemplate!
          .config! as TemplateEditor.TemplateModel;
        this.values.default = this.initialTemplate!.default;
        this.editorTitle = this.$t("templates.edit").toString();
      } catch (error) {
        this.errorRedirect({
          location: { name: "template-manager" },
          error
        });
        return;
      }
    }
    this.configuration = await templatesService.configuration(
      this.values!.type
    );
    this.factory.init(this.values);
    this.loading = false;
  }

  protected async saveTemplate() {
    let templateToSave = {};
    if (this.initialTemplate) {
      templateToSave = {
        ...this.initialTemplate
      };
    }
    const values = cloneDeep(this.values) 
    values!.tree!.map(item => {
      item.model.children!.forEach((value, index) => {
        if (
          value.model.children![index] &&
          value.model.children![index].model &&
          value.model.children![index].model.image
        ) {
          value.model.children![index].model.image = "";
        }
      });
    });
    templateToSave = {
      ...templateToSave,
      ...values,
      config: {
        ...this.values
      }
    };
    this.loading = true;
    await this.saveTemplateModel({
      ...templateToSave
    });
    this.loading = false;
  }

  protected printTemplate() {
    EventBus.$emit("print", {
      component: CanvasTreeComponent,
      props: {
        treeData: this.values && this.values.tree,
        configuration: this.configuration,
        printMode: true
      }
    });
  }

  protected cancel() {
    this.$router.push({ name: "template-manager" });
  }
  protected edit(block: TemplateEditor.UIBlocks.Block) {
    this.activeBlock = block;
  }

  protected get composedTitle() {
    return this.values && this.values.name
      ? [this.editorTitle, " - ", this.values!.name].join("")
      : this.editorTitle;
  }
}
