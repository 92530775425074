import { messagesService } from "@/services/messages.service";
import { store } from "@/store";
import { Component } from "vue-property-decorator";
import ConfigurationField from "../ConfigurationField.mixin";
import { ConfigurationFields } from "./../declarations";
import Template from "./UploadFile.template.vue";

@Component({
  mixins: [Template]
})
export default class ConfigurationFieldUploadFileComponent extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.UploadFile = {
    show_preview: false,
    show_label: true,
    title: "",
    label: "Upload"
  };

  protected addFile(event: Event) {
    // @ts-ignore
    const uploaded = event!.target!.files[0];
    if (uploaded) {
      // when user uploads file
      const fileExtension = uploaded.name.match(/\.([^\.]+)$/)![1];
      const fileSize = uploaded.size / 1000000;
      const invalidFileName = /([^a-zA-Z0-9_.\-])/.test(uploaded.name)
        ? true
        : false;
      const invalidDocMsg: string | string[] = [];
      if (
        !store.getters["AuthModule/fileImageExtention"].includes(fileExtension)
      ) {
        invalidDocMsg.push(
          String(
            this.$t("security.invalid_file_extention", {
              fileExtention: store.getters["AuthModule/fileImageExtention"]
            })
          )
        );
      }
      if (fileSize > store.getters["AuthModule/fileUploadLimit"]) {
        invalidDocMsg.push(
          String(
            this.$t("security.invalid_file_size", {
              fileSize: store.getters["AuthModule/fileUploadLimit"]
            })
          )
        );
      }
      if (invalidFileName) {
        invalidDocMsg.push(String(this.$t("security.invalid_file_name")));
      }
      if (invalidDocMsg.length) {
        messagesService.showMessage(
          "fal fa-exclamation-triangle",
          invalidDocMsg,
          "error"
        );
      } else {
        this.toBase64(uploaded);
      }
    }
  }

  protected toBase64(file: File) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.input(reader.result);
    };
    reader.readAsDataURL(file);
  }
}
