import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { pusherEvents } from "@/enums/pusherEvents";
import { PusherNotification } from "@/interfaces/notification";
import { TemplateConfigField } from "@/interfaces/templateEditor";
import { messagesService } from "@/services/messages.service";
import { templatesService } from "@/services/templates.service";
import { Callback, PageNavAction } from "@/types/types";
import { ActionsSubheaderComponent } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import { ZoomConfig } from "../layout/zoomControl/zoomControl.component";
@Component({
  inject: ["$changes"]
})
export default class TemplateMixin extends Vue {
  public templateModel!: TemplateEditor.TemplateModel;
  public isEdit: boolean = false;
  public zoomLevel = 1;
  public zoomConf: ZoomConfig = {
    zoomLevel: this.zoomLevel,
    maxValue: 1.5,
    minValue: 0.5,
    step: 0.1
  };
  public currentlyEditing: string = "";
  public showEditor = false;
  public loading = false;

  @Action("errorRedirect", { namespace: "RouterModule" })
  protected errorRedirect!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  protected setPageNav!: PageNavAction;

  get titleActions() {
    return {
      left: [
        {
          icon: "fal fa-print",
          action: this.print,
          vuetifyProps: () => ({
            loading: this.loading,
            fab: true,
            small: true
          })
        }
      ],
      right: [
        {
          icon: "fal fa-check",
          action: this.save,
          vuetifyProps: () => ({
            loading: this.loading,
            fab: true,
            small: true
          })
        },
        {
          icon: "fal fa-times",
          action: this.cancel,
          vuetifyProps: () => ({
            loading: this.loading,
            fab: true,
            small: true
          })
        }
      ]
    };
  }

  public print() {
    // Method to be overriden per implementation
  }

  public cancel() {
    this.$router.push({ name: "template-manager" });
  }

  public openEditor(fieldData: { field: string }) {
    this.showEditor = true;
    this.currentlyEditing = fieldData.field;
  }

  get fieldToEdit() {
    return {
      field: this.currentlyEditing,
      ...this.templateModel.config![this.currentlyEditing]
    };
  }

  public closeEditor() {
    this.showEditor = false;
    this.currentlyEditing = "";
  }

  public fieldHandler(data: any) {
    this.templateModel.config![this.currentlyEditing].fieldDisplay =
      data.fieldDisplay;
  }

  public visibilityChanged(data: {
    fieldName: string;
    configField: TemplateConfigField;
  }) {
    this.templateModel.config![data.fieldName].visible =
      data.configField.visible;
  }

  public zoomHandler(zoom: number) {
    this.zoomLevel = zoom;
  }

  public async save() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await templatesService.put(this.templateModel, this.templateModel);
      } else {
        await templatesService.post(this.templateModel);
      }
      this.loading = false;
      messagesService.renderSuccessMessage("template_model_saved");
      this.cancel();
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async getTemplate(templateId: string) {
    this.loading = true;
    const templateData: TemplateEditor.TemplateModel | null = await templatesService.getTemplate(
      templateId
    );
    if (templateData) {
      this.templateModel = templateData;
      this.isEdit = true;
    }
    this.loading = false;
  }

  protected async created() {
    const templateId = this.$route.params.id;
    this.setPageNav({
      title: this.$route.path.includes("BARCODE")
        ? "template.barcode.template"
        : `Receipt Template - ${this.templateModel.name}`,
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => this.titleActions.right
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: this.titleActions.left
        }
      }
    });

    if (templateId) {
      try {
        await this.getTemplate(templateId);
      } catch (error) {
        this.errorRedirect({
          location: { name: "template-manager" },
          error
        });
      }

      this.$changes.watch(
        pusherEvents.templateTouched,
        () => {
          this.getTemplate(templateId);
        },
        (data: PusherNotification) =>
          !!data.message.find(n => n.item_id === +this.$route.params.id)
      );
    }
  }
}
