import FieldEditorComponent from "@/components/template/layout/fieldEditor/fieldEditor.component";
import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { EventBus } from "@/internal";
import omitBy from "lodash/omitBy";
import { Component, Mixins } from "vue-property-decorator";
import ElementSidebarComponent from "../layout/elementSidebar/elementSidebar.component";
import ZoomControlComponent from "../layout/zoomControl/zoomControl.component";
import TemplateMixin from "../mixins/template.mixin";
import { receiptDefault } from "./default";
import ReceiptEditComponent from "./edit/ReceiptEdit.component";
import Template from "./ReceiptTemplate.template.vue";

@Component({
  mixins: [Template],
  components: {
    ReceiptEditComponent,
    FieldEditorComponent,
    ElementSidebarComponent,
    ZoomControlComponent
  }
})
export default class ReceiptTemplateComponent extends Mixins(TemplateMixin) {
  public isEdit = false;
  public templateModel: TemplateEditor.TemplateModel = {
    name: "Receipt Template",
    type: "RECEIPT",
    default: false,
    config: receiptDefault
  };

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error").toString();
  }

  get hideableFields() {
    return omitBy(
      this.templateModel.config,
      c => !c!.hasOwnProperty("visible")
    );
  }

  public print() {
    EventBus.$emit("print", {
      component: ReceiptEditComponent,
      props: { templateConfig: this.templateModel.config, print: true }
    });
  }
}
