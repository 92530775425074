import { FieldDisplay } from "@/interfaces/templateEditor";
import { messagesService } from "@/services/messages.service";
import { store } from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./imageEdit.template.vue";

@Component({
  mixins: [Template]
})
export default class ImageEditComponent extends Vue {
  @Prop({ required: true })
  public fieldDisplay!: FieldDisplay;
  public invalidFileName: boolean = false;
  public fileOptions = {
    accept: "image/*"
  };
  public imageModel: {
    width: string;
    height: string;
    fileSrc: string | null | ArrayBuffer;
  } = {
    width: "100",
    height: "80",
    fileSrc: ""
  };

  constructor() {
    super();
  }

  public onFileChange(e: any) {
    const reader = new FileReader();
    const file = e.target.files[0];
    // getting file extension
    const fileExtension = file.name.match(/\.([^\.]+)$/)[1];
    // getting file size in "mega bytes" by dividing with 1000000
    const fileSize = file.size / 1000000;
    // only " alphanumeric,_ , - , ." characters are allowed in file name
    if (/([^a-zA-Z0-9_.\-])/.test(file.name)) {
      this.invalidFileName = true;
    } else {
      this.invalidFileName = false;
    }
    const invalidDocMsg: string | string[] = [];
    if (
      !store.getters["AuthModule/fileImageExtention"].includes(fileExtension)
    ) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_extention", {
            fileExtention: store.getters["AuthModule/fileImageExtention"]
          })
        )
      );
    }
    if (fileSize > store.getters["AuthModule/fileUploadLimit"]) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_size", {
            fileSize: store.getters["AuthModule/fileUploadLimit"]
          })
        )
      );
    }
    if (this.invalidFileName) {
      invalidDocMsg.push(String(this.$t("security.invalid_file_name")));
    }
    if (invalidDocMsg.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        invalidDocMsg,
        "error"
      );
    } else {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imageModel.fileSrc = reader.result;
        this.sendModel();
      };
    }
  }

  public sendModel() {
    this.$emit("styleChanged", {
      style: {
        width: this.imageModel.width,
        height: this.imageModel.height
      },
      fileSrc: this.imageModel.fileSrc
    });
  }

  protected mounted() {
    if (this.fieldDisplay) {
      this.imageModel = {
        width: this.fieldDisplay.style.width,
        height: this.fieldDisplay.style.height,
        fileSrc: this.fieldDisplay.fileSrc || null
      };
    }
  }
}
