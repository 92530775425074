import { Component } from "vue-property-decorator";
import Template from "./RadioGroup.template.vue";

import ConfigurationField from "../ConfigurationField.mixin";
import { ConfigurationFields } from "../declarations";

@Component({
  mixins: [Template]
})
export default class ConfigurationFieldRadioGroup extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.RadioGroup = {
    items: [],
    label: ""
  };
}
