import { Component } from "vue-property-decorator";
import Template from "./Text.template.vue";

import ConfigurationField from "../ConfigurationField.mixin";
import { ConfigurationFields } from "../declarations";

@Component({
  mixins: [Template]
})
export default class ConfigurationFieldText extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.Text = {
    type: "text"
  };
}
