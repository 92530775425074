import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./zoomControl.template.vue";

export interface ZoomConfig {
  zoomLevel: number;
  maxValue: number;
  minValue: number;
  step: number;
}

@Component({
  mixins: [Template]
})
export default class ZoomControlComponent extends Vue {
  @Prop({ required: true })
  public config!: ZoomConfig;
  public zoomLevel: number = this.config.zoomLevel;
  constructor() {
    super();
  }

  public zoom(isZoomIn: boolean = false) {
    const newZoom = +this.zoomLevel + (isZoomIn ? 0.1 : -0.1);
    this.zoomLevel = +newZoom.toFixed(1);
    this.sendZoom();
  }

  public sendZoom() {
    this.$emit("zoomChanged", this.zoomLevel);
  }
}
