import { FieldDisplay } from "@/interfaces/templateEditor";
import debounce from "lodash/debounce";
import { Component } from "vue-property-decorator";
import varEditComponent from "../varEdit/varEdit.component";
import Template from "./customText.template.vue";

@Component({
  mixins: [Template]
})
export default class CustomTextComponent extends varEditComponent {
  public dSendModel = debounce(this.sendModel, 700);
  public model: FieldDisplay = {
    style: {
      fontSize: "14",
      lineHeight: "21",
      alignSelf: "center",
      textAlign: "center"
    },
    value: ""
  };
  constructor() {
    super();
  }
}
