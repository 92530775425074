import TemplateEditorComponent from "@/components/templateEditor/TemplateEditor.component";
import { Component, Vue } from "vue-property-decorator";
import BarcodeTemplateComponent from "./barcode/BarcodeTemplate.component";
import ReceiptTemplateComponent from "./receipt/ReceiptTemplate.component";
import Template from "./Template.template.vue";

@Component({
  mixins: [Template],
  components: {
    ReceiptTemplateComponent,
    BarcodeTemplateComponent,
    TemplateEditorComponent
  }
})
export default class TemplateComponent extends Vue {
  public templateType: string = "";
  protected templateComponents = {
    receipt: ReceiptTemplateComponent,
    barcode: BarcodeTemplateComponent,
    inventory: TemplateEditorComponent,
    customer: TemplateEditorComponent
  };

  protected created() {
    this.templateType = this.$route.params.type.toLowerCase();
  }
}
