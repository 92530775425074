import { Component } from "vue-property-decorator";
import Template from "./Select.template.vue";

import ConfigurationField from "../ConfigurationField.mixin";
import { ConfigurationFields } from "../declarations";

@Component({
  mixins: [Template]
})
export default class ConfigurationFieldVariable extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.Select = {
    items: []
  };
}
