import { Component } from "vue-property-decorator";
import { ConfigurationFields } from "../declarations";
import Template from "./BooleanButtonsGroup.template.vue";

import ConfigurationFieldBooleanButton from "../BooleanButton/BooleanButton.component";
import ConfigurationField from "../ConfigurationField.mixin";

@Component({
  mixins: [Template],
  components: {
    "boolean-button": ConfigurationFieldBooleanButton
  }
})
export default class ConfigurationFieldBooleanButtonsGroup extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.BooleanButtonsGroup = {
    buttons: []
  };
}
