import { render, staticRenderFns } from "./ColorPicker.template.vue?vue&type=template&id=047338be&scoped=true&"
var script = {}
import style0 from "./ColorPicker.template.vue?vue&type=style&index=0&id=047338be&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "047338be",
  null
  
)

export default component.exports