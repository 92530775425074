import VueBarcode from "vue-barcode";
import { Component, Mixins } from "vue-property-decorator";
import EditMixin from "../../mixins/edit.mixin";
import Template from "./ReceiptEdit.template.vue";

@Component({
  mixins: [Template],
  components: {
    VueBarcode
  }
})
export default class ReceiptEditComponent extends Mixins(EditMixin) {
  constructor() {
    super();
  }

  get customText() {
    return (
      (this.templateConfig.customText.fieldDisplay &&
        this.templateConfig.customText.fieldDisplay.value) ||
      "[Custom Text]"
    );
  }
}
