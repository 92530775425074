import { Component } from "vue-property-decorator";
import Template from "./BooleanButton.template.vue";

import ConfigurationField from "../ConfigurationField.mixin";
import { ConfigurationFields } from "../declarations";

@Component({
  mixins: [Template]
})
export default class ConfigurationFieldBooleanButton extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.BooleanButton = {
    label: "Default label",
    icon: "fal fa-times"
  };

  protected get icon() {
    return this.config["active-icon"] && this.value
      ? this.config["active-icon"]
      : this.config.icon;
  }

  protected changeValue() {
    const change = this.value ? this.config.value : !this.value;
    this.input(change);
  }

  protected get selected() {
    return !!this.config.value ? this.config.value === this.value : this.value;
  }
}
