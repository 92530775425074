import { TemplateEditor } from "@/components/templateEditor/editors.declaration";

import { EventBus } from "@/internal";
import cloneDeep from "lodash/cloneDeep";

export default class TemplateItemsFactoryComponent {
  protected model!: TemplateEditor.TemplateModel;

  constructor() {
    EventBus.$on(
      "TemplateEditor.SplitColumn",
      (data: {
        breadcrumb: number[];
        direction: "horizontal" | "vertical";
      }) => {
        this.splitColumn(
          this.findByBreadcrumb(
            data.breadcrumb
          ) as TemplateEditor.UIBlocks.LayoutColumn,
          data.direction
        );
      }
    );
    EventBus.$on(
      "TemplateEditor.AddColumn",
      (data: { breadcrumb: number[] }) => {
        const row = this.findByBreadcrumb(
          data.breadcrumb
        ) as TemplateEditor.UIBlocks.LayoutRow;
        if (row.type === "row-item") {
          this.addColumn(row);
        }
      }
    );
    EventBus.$on("TemplateEditor.AddRow", (data: { breadcrumb: number[] }) => {
      const column = this.findByBreadcrumb(
        data.breadcrumb
      ) as TemplateEditor.UIBlocks.LayoutColumn;
      if (column.type === "column-item") {
        const hasRows = column.model.children[0].type === "row-item";
        if (hasRows) {
          this.addRow(column);
        } else {
          this.splitColumn(column, "vertical");
        }
      }
    });
    EventBus.$on(
      "TemplateEditor.RemoveElement",
      (data: { breadcrumb: number[] }) => {
        this.findAndDelete(data.breadcrumb);
        this.cleanEmptyTree(data.breadcrumb);
      }
    );
    EventBus.$on("TemplateEditor.ClearTree", () => {
      // ToDO
    });
    EventBus.$on(
      "TemplateEditor.MoveElement",
      (data: { element: number[]; to: number[]; position?: number }) => {
        this.findAndPush(data.to, this.findByBreadcrumb(
          data.element
        ) as TemplateEditor.UIBlocks.BaseBlockModel);
        this.findAndDelete(data.element);
        this.cleanEmptyTree(data.element);
      }
    );
    EventBus.$on(
      "TemplateEditor.AddNew",
      (data: { block: TemplateEditor.UIBlocks.BaseBlockModel }) => {
        const rootRow = this.addRootRow();
        const column = this.addColumn(
          rootRow
        ) as TemplateEditor.UIBlocks.LayoutColumn;
        this.addItem(column, data.block);
      }
    );
  }

  public init(model: TemplateEditor.TemplateModel) {
    this.model = model;
  }

  public splitColumn(
    column: TemplateEditor.UIBlocks.LayoutColumn,
    direction: "horizontal" | "vertical" = "horizontal"
  ) {
    if (column && column.model.children) {
      if (direction === "horizontal") {
        const row = cloneDeep(RowBlank);
        const splitted = [cloneDeep(ColumnBlank), cloneDeep(ColumnBlank)];
        splitted[0].model.children = [
          ...splitted[0].model.children,
          ...column.model.children
        ];
        row.model.children = row.model.children.concat(splitted);
        column.model.children = [row];
      } else {
        const splitted = [cloneDeep(RowBlank), cloneDeep(RowBlank)];
        splitted[0].model.children = [cloneDeep(column)];
        splitted[1].model.children = [cloneDeep(ColumnBlank)];
        column.model.children = splitted;
      }
    }
  }
  //
  public addColumn(
    container: TemplateEditor.UIBlocks.LayoutRow
  ): TemplateEditor.UIBlocks.LayoutItem {
    const newColumn = cloneDeep(ColumnBlank);
    container.model.children.push(newColumn);
    return newColumn;
  }
  public addRootRow(): TemplateEditor.UIBlocks.LayoutRow {
    const newRow = cloneDeep(RowBlank);
    this.model.tree!.push(newRow);
    return newRow;
  }
  public addRow(
    container: TemplateEditor.UIBlocks.LayoutColumn
  ): TemplateEditor.UIBlocks.LayoutItem {
    const newRow = cloneDeep(RowBlank);
    container.model.children.push(newRow);
    return newRow;
  }
  public addItem(
    container: TemplateEditor.UIBlocks.LayoutItem,
    item: TemplateEditor.UIBlocks.BaseBlockModel
  ): TemplateEditor.UIBlocks.LayoutItem | undefined {
    if (
      container &&
      container.type === "column-item" &&
      container.model.children
    ) {
      container.model.children.push(item);
    }
    return container;
  }
  //
  public removeItem(breadcrumb: number[]) {
    this.findAndDelete(breadcrumb);
  }

  //////////////////////////////////////////////////////////////////////

  protected findByBreadcrumb(
    breadcrumb: number[],
    parent?: TemplateEditor.UIBlocks.LayoutItem[]
  ): TemplateEditor.UIBlocks.LayoutItem | undefined {
    breadcrumb = [...breadcrumb];
    const findOn = parent ? parent : this.model.tree!;
    const stepIndex = breadcrumb[0];
    if (breadcrumb.length === 1 && !!findOn[stepIndex]) {
      return findOn[stepIndex];
    } else if (!!findOn[stepIndex] && !!findOn[stepIndex].model.children) {
      return this.findByBreadcrumb(
        breadcrumb.splice(1),
        findOn[stepIndex].model.children
      );
    }
  }
  protected findAndPush(
    breadcrumb: number[],
    item: TemplateEditor.UIBlocks.BaseBlockModel
  ): void {
    breadcrumb = [...breadcrumb];
    const f = this.findByBreadcrumb(breadcrumb);
    if (f && f.model.children) {
      f.model.children.push(item);
    }
  }
  protected findAndDelete(breadcrumb: number[]): void {
    breadcrumb = [...breadcrumb];
    const last = breadcrumb[breadcrumb.length - 1];
    if (breadcrumb.length > 1) {
      const p = this.findByBreadcrumb(
        (breadcrumb = breadcrumb.splice(0, breadcrumb.length - 1))
      );
      if (p && p.model.children) {
        p.model.children.splice(last, 1);
      }
    } else {
      this.model.tree!.splice(last, 1);
    }
  }
  protected cleanEmptyTree(breadcrumb: number[]) {
    breadcrumb = [...breadcrumb].splice(0, breadcrumb.length - 1);
    const container = this.findByBreadcrumb(breadcrumb);
    if (container && !container.model.children!.length) {
      this.findAndDelete(breadcrumb);
      if (breadcrumb.length) {
        this.cleanEmptyTree(breadcrumb);
      }
    }
  }
}

const RowBlank: TemplateEditor.UIBlocks.LayoutRow = {
  type: "row-item",
  model: {
    children: []
  }
};

const ColumnBlank: TemplateEditor.UIBlocks.LayoutColumn = {
  type: "column-item",
  model: {
    children: []
  }
};
