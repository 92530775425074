import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./configurationStep.template.vue";

import TemplateEditor from "@/components/templateEditor/editors.declaration";
import ConfigurationFieldActionButton from "../../fields/ActionButton/ActionButton.component";
import ConfigurationFieldBooleanButton from "../../fields/BooleanButton/BooleanButton.component";
import ConfigurationFieldBooleanButtonsGroup from "../../fields/BooleanButtonsGroup/BooleanButtonsGroup.component";
import ConfigurationFieldCheckbox from "../../fields/Checkbox/Checkbox.component";
import ConfigurationFieldColorPicker from "../../fields/ColorPicker/ColorPicker.component";
import ConfigurationFieldRadioGroup from "../../fields/RadioGroup/RadioGroup.component";
import ConfigurationFieldRange from "../../fields/Range/Range.component";
import ConfigurationFieldSelect from "../../fields/Select/Select.component";
import ConfigurationFieldSwitch from "../../fields/Switch/Switch.component";
import ConfigurationFieldText from "../../fields/Text/Text.component";
import ConfigurationFieldUploadFileComponent from "../../fields/UploadFile/UploadFile.component";

@Component({
  mixins: [Template],
  components: {
    "action-button-field": ConfigurationFieldActionButton,
    "boolean-button-field": ConfigurationFieldBooleanButton,
    "boolean-buttons-group-field": ConfigurationFieldBooleanButtonsGroup,
    "checkbox-field": ConfigurationFieldCheckbox,
    "color-picker-field": ConfigurationFieldColorPicker,
    "radiogroup-field": ConfigurationFieldRadioGroup,
    "range-field": ConfigurationFieldRange,
    "select-field": ConfigurationFieldSelect,
    "switch-field": ConfigurationFieldSwitch,
    "text-field": ConfigurationFieldText,
    "upload-file-field": ConfigurationFieldUploadFileComponent
  }
})
export default class ConfigurationStepComponent extends Vue {
  @Prop({ required: true })
  public block!: TemplateEditor.UIBlocks.Block;

  protected goBack() {
    this.$emit("close");
  }

  protected fieldType(type: string) {
    return [type, "field"].join("-");
  }

  protected fieldValue(key: string): any {
    // @ts-ignore
    return this.block && this.block.model ? this.block.model[key] : "";
  }

  protected get configuration(): any {
    return this.block.configuration;
  }

  protected get values() {
    return this.block.model;
  }

  protected fieldConfiguration(field: any, fieldKey: string) {
    const base = this.configuration.fields
      ? this.configuration.fields[fieldKey]
      : {};
    return { ...field.configuration, ...base };
  }
}
