import { Component } from "vue-property-decorator";
import ConfigurationField from "../ConfigurationField.mixin";
import { ConfigurationFields } from "../declarations";
import Template from "./Switch.template.vue";

@Component({
  mixins: [Template]
})
export default class ConfigurationFieldSwitch extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.Switch = {};
}
