import { Component } from "vue-property-decorator";
import ConfigurationField from "../ConfigurationField.mixin";
import { ConfigurationFields } from "../declarations";
import Template from "./Range.template.vue";

@Component({
  mixins: [Template]
})
export default class ConfigurationFieldRange extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.Range = {
    min: 0,
    max: 100,
    step: 1
  };
}
