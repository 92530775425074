import { FieldDisplay } from "@/interfaces/templateEditor";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./varEdit.template.vue";

@Component({
  mixins: [Template]
})
export default class VarEditComponent extends Vue {
  @Prop({ required: true })
  public fieldDisplay!: FieldDisplay | null;
  public model: FieldDisplay = {
    style: {
      fontSize: "14",
      lineHeight: "21",
      alignSelf: "center",
      textAlign: "center",
      marginTop: "8",
      marginBottom: "8"
    }
  };
  constructor() {
    super();
  }

  public sendModel() {
    this.$emit("styleChanged", this.fieldModel);
  }

  public alignHandler() {
    const align = this.fieldModel.style.alignSelf;
    this.fieldModel.style.textAlign =
      align === "flex-start"
        ? "left"
        : align === "flex-end"
        ? "right"
        : "center";
    this.sendModel();
  }

  get fieldModel() {
    return cloneDeep(this.fieldDisplay || this.model);
  }
}
