import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import customTextComponent from "./customText/customText.component";
import Template from "./fieldEditor.template.vue";
import imageEditComponent from "./imageEdit/imageEdit.component";
import varEditComponent from "./varEdit/varEdit.component";

@Component({
  mixins: [Template],
  components: {
    imageEditComponent,
    varEditComponent,
    customTextComponent
  }
})
export default class FieldEditorComponent extends Vue {
  @Prop({ required: true })
  public fieldData!: any;
  public editComponents: any = {
    image: imageEditComponent,
    variable: varEditComponent,
    custom: customTextComponent
  };

  constructor() {
    super();
  }
  public close() {
    this.$emit("closeEditor");
  }

  get fieldDisplayCopy() {
    return cloneDeep(this.fieldData.fieldDisplay);
  }
  public styleHandler(data: any) {
    this.$emit("fieldChanged", {
      field: this.fieldData.field,
      fieldDisplay: data
    });
  }
}
