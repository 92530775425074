import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { EventBus } from "@/internal";
import { Component, Mixins } from "vue-property-decorator";
import BarcodeEditComponent from "../barcode/edit/barcodeEdit.component";
import ElementSidebarComponent from "../layout/elementSidebar/elementSidebar.component";
import FieldEditorComponent from "../layout/fieldEditor/fieldEditor.component";
import ZoomControlComponent from "../layout/zoomControl/zoomControl.component";
import TemplateMixin from "../mixins/template.mixin";
import Template from "./BarcodeTemplate.template.vue";
import { barcodeDefault } from "./default";

@Component({
  mixins: [Template],
  components: {
    BarcodeEditComponent,
    FieldEditorComponent,
    ElementSidebarComponent,
    ZoomControlComponent
  }
})
export default class BarcodeTemplateComponent extends Mixins(TemplateMixin) {
  public templateModel: TemplateEditor.TemplateModel = {
    name: "Barcode Template",
    type: "BARCODE",
    default: false,
    config: barcodeDefault
  };
  constructor() {
    super();
  }

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error").toString();
  }

  public print() {
    EventBus.$emit("print", {
      component: BarcodeEditComponent,
      props: { templateConfig: this.templateModel.config, print: true }
    });
  }
}
