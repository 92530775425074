import { Component } from "vue-property-decorator";
import ConfigurationField from "../ConfigurationField.mixin";
import { ConfigurationFields } from "../declarations";
import Template from "./ColorPicker.template.vue";

@Component({
  mixins: [Template]
})
export default class ConfigurationFieldColorPicker extends ConfigurationField {
  protected defaultConfiguration: ConfigurationFields.ColorPicker = {
    type: "rgb",
    label: ""
  };
  protected apply: boolean = false;
  protected color: string = "";

  public input(value: string) {
    this.color = value;
    this.$emit("input", this.apply ? this.color : null);
  }

  public applyColor(value: string) {
    this.apply = !this.apply;
    this.input(this.color);
  }
}
